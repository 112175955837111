import { Div, Dropdown, GlobalStatus, P, Section } from '@dnb/eufemia';
import type {
  AggregationProvider,
  AggregationProviderResponseDto,
} from '@portals/shared/portal/AggregationProviderDto';
import type { ApiDto } from '@portals/shared/portal/ApiDto';
import type { ApiEnvironmentDto } from '@portals/shared/portal/ApiEnvironmentDto';
import { type JSX, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import { getApiMetadata } from '@/api/apiMetadata';
import Container from '@/components/Container';
import DataTable, { type Column } from '@/components/DataTable';
import LoadingModal from '@/components/LoadingModal';
import Page from '@/components/Page';

import style from './AggregationProviders.module.css';

const EXTERNAL_ACCOUNTS_SLUG = 'external-accounts';
type DropdownOption<T> = { selected_key: T; content: string };

interface ProviderRow {
  id: string;
  name: string;
  country: string;
  paymentEnabled: string;
  domesticPaymentMethods: string;
  internationalPaymentMethods: string;
  operationalStatus: string;
}

const COLUMNS: Column<ProviderRow>[] = [
  { header: 'Name', attribute: 'name' },
  { header: 'Id', attribute: 'id' },
  { header: 'Country', attribute: 'country' },
  { header: 'Payments Enabled', attribute: 'paymentEnabled' },
  { header: 'Domestic Payment Methods', attribute: 'domesticPaymentMethods' },
  {
    header: 'International Payment Methods',
    attribute: 'internationalPaymentMethods',
  },
  { header: 'Operational Status', attribute: 'operationalStatus' },
];

export default function AggregationProviders(): JSX.Element {
  const [searchParams, setSearchParam] = useSearchParams();

  const { data: apis, isValidating: loadingApis } = useSWR<ApiDto[]>('/api');

  const [environments, setEnvironments] = useState<DropdownOption<string>[]>();
  const [channels, setChannels] = useState<string[]>();
  const [configs, setConfigs] = useState<string[]>();

  const [activeEnvironment, setActiveEnvironment] = useState(
    searchParams.get('environment') ?? undefined,
  );
  const [activeChannel, setActiveChannel] = useState(
    searchParams.get('channel') ?? undefined,
  );
  const [activeConfig, setActiveConfig] = useState(
    searchParams.get('config') ?? undefined,
  );

  const [activeProviders, setActiveProviders] = useState<{
    [key: string]: AggregationProvider[];
  }>();
  const [providers, setProviders] = useState<{
    [value: string]: AggregationProviderResponseDto;
  }>();

  useEffect(() => {
    if (activeEnvironment) {
      searchParams.set('environment', activeEnvironment);
      setSearchParam(searchParams);
    }
  }, [activeEnvironment]);

  useEffect(() => {
    if (activeChannel) {
      searchParams.set('channel', activeChannel);
      setSearchParam(searchParams);
    }
  }, [activeChannel]);

  useEffect(() => {
    if (activeConfig) {
      searchParams.set('config', activeConfig);
      setSearchParam(searchParams);
    }
  }, [activeConfig]);

  useEffect(() => {
    if (apis) {
      const api = apis.find(({ slug }) => slug === EXTERNAL_ACCOUNTS_SLUG);

      if (!api) {
        fetchMetadata([]);
        return;
      }

      const _environments = api.environments.map(
        (environment: ApiEnvironmentDto) => ({
          selected_key: environment.slug,
          content: environment.name,
        }),
      );
      setEnvironments(_environments);
      fetchMetadata(api.environments.map((environment) => environment.slug));

      const shouldKeepActiveEnvironment =
        !!activeEnvironment &&
        _environments.some((env) => env.selected_key === activeEnvironment);

      if (!shouldKeepActiveEnvironment) {
        const defaultEnvironment =
          api.environments.find(({ isDefault }) => isDefault)?.slug ??
          api.environments[0].slug;
        setActiveEnvironment(defaultEnvironment);
      }
    }
  }, [apis]);

  const fetchMetadata = useCallback(
    async (environments: string[]) => {
      const metadata = await Promise.all(
        environments.map(async (environment) => {
          try {
            const response =
              await getApiMetadata<AggregationProviderResponseDto>(
                EXTERNAL_ACCOUNTS_SLUG,
                environment,
              );
            return {
              [environment]: response,
            };
          } catch {
            return {};
          }
        }),
      );

      const providers = metadata.reduce(
        (accumulator, currentValue) => Object.assign(accumulator, currentValue),
        {},
      );

      setProviders(providers);
    },
    [setProviders],
  );

  useEffect(() => {
    if (providers && activeEnvironment) {
      const channels = Object.keys(providers[activeEnvironment]);
      setChannels(channels);

      const shouldKeepActiveChannel =
        !!activeChannel && channels.includes(activeChannel);

      if (!shouldKeepActiveChannel) {
        setActiveChannel(channels[0]);
      }
    }
  }, [providers, activeEnvironment]);

  useEffect(() => {
    if (providers && activeEnvironment && activeChannel) {
      const provider = providers[activeEnvironment]?.[activeChannel];
      if (provider) {
        setActiveProviders(provider);

        const configs = Object.keys(provider);
        setConfigs(configs);

        const shouldKeepActiveConfig =
          !!activeConfig && configs.includes(activeConfig);

        if (!shouldKeepActiveConfig) {
          // Prefer LIVE if present
          if (configs.includes('LIVE')) {
            setActiveConfig('LIVE');
          } else {
            setActiveConfig(configs[0]);
          }
        }
      }
    }
  }, [providers, activeEnvironment, activeChannel]);

  const rows = useMemo(() => {
    if (!activeProviders || !activeConfig || !activeProviders[activeConfig]) {
      return [];
    }
    return activeProviders[activeConfig]?.map<ProviderRow>(
      ({ countryCode, id, name, paymentMethods, paymentEnabled, status }) => ({
        id,
        name,
        country: countryCode,
        paymentEnabled: paymentEnabled ? 'Yes' : 'No',
        domesticPaymentMethods:
          paymentMethods?.domestic?.executionTypes.join(',') ?? '',
        internationalPaymentMethods:
          paymentMethods?.international?.executionTypes.join(',') ?? '',
        operationalStatus:
          status.syncMode === 'Full'
            ? 'Fully operational'
            : 'Partially operational',
      }),
    );
  }, [activeConfig, activeProviders]);

  if (loadingApis) {
    return <LoadingModal />;
  }

  return (
    <Page
      description={
        <Container size="small">
          <P>
            Provider Config is a list of banks supported by Aggregation Data.
            This list is specific to the selected service and environment.
          </P>
          <P top="small">
            For each provider, we supply information about the type of payments
            that the provider supports, both for domestic as well as
            international. The various payments methods are Normal, Instant and
            SpecificDate.
          </P>
          <P top="small">
            If Sync is full, it means that aggregation can perform unattended
            account details requests on a user&apos;s behalf, without needing
            strong costumer authentication.
          </P>
        </Container>
      }
      spacing={
        providers && Object.keys(providers).length === 0
          ? 'x-small'
          : 'xx-large'
      }
      subtitle="Providers"
      title="Supported bank providers"
    >
      <Container centered>
        {providers && Object.keys(providers).length === 0 && (
          <GlobalStatus
            autoscroll
            bottom="medium"
            hide_close_button
            id="provider-error"
            show
            title="An error has occured, no providers was found. Please reach out to the developer portal team."
          />
        )}
        <Section style_type="white">
          <DataTable
            barContent={
              <Div className={style['Filters']}>
                <Dropdown
                  className={style['dnb-dropdown']}
                  data={channels}
                  on_change={({ data }) => setActiveChannel(data)}
                  title="Select Channel"
                  value={activeChannel && channels?.indexOf(activeChannel)}
                />

                <Dropdown
                  className={style['dnb-dropdown']}
                  data={configs}
                  on_change={({ data }) => setActiveConfig(data)}
                  title="Select Config"
                  value={activeConfig && configs?.indexOf(activeConfig)}
                />

                <Dropdown
                  className={style['dnb-dropdown']}
                  data={environments}
                  on_change={({ data }) =>
                    setActiveEnvironment(data.selected_key)
                  }
                  title="Select environment"
                  value={activeEnvironment}
                />
              </Div>
            }
            columns={COLUMNS}
            data={rows}
            defaultSortKey="name"
            filterBy={['name', 'id', 'country']}
          />
        </Section>
      </Container>
    </Page>
  );
}
