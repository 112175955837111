export const URL_MATCHER = /^\/[^\n]+$/;
export const KEY_MATCHER = /[_a-z]+/;
export const UUID_MATCHER = /^[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}$/i;
export const BAID_MATCHER = /^BA\d{5}$/;
export const BSNID_MATCHER = /^BSN\d{7}$/;
export const DATE_MATCHER = /^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])$/;
export const TIME_MATCHER = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
export const REQUEST_ID_MATCHER = /^[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}$/i;
export const PASSWORD_MATCHER =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[!#$%&*?@^-]).{8,}$/;
export const REDIRECT_URIS_MATCHER = /^https:\/\//;
